import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { PrimaryButton } from 'components';
import quizSvgs from 'utils/constants/quizSvgs';

interface IconListProps {
  onContinue: () => void;
}

const IconList: FC<IconListProps> = ({ data, onContinue, ...props }) => {
  const renderSvgImage = (svgKey: string) => {
    const Svg = quizSvgs[svgKey];
    return (
      <SvgContainer>
        <Svg />
      </SvgContainer>
    );
  };

  return (
    <Container {...props}>
      <Title dangerouslySetInnerHTML={{ __html: data?.label }} />
      <ListContainer>
        {data?.list.map((item, index) => (
          <ListItem key={index}>
            {renderSvgImage(item.icon)}
            <TextContainer>
              <ListTitle>{item.title}</ListTitle>
              <ListText>{item.text}</ListText>
            </TextContainer>
          </ListItem>
        ))}
      </ListContainer>
      <ButtonContainer>
        <ContinueButtonStyled onClick={onContinue}>
          {data?.buttonTitle}
        </ContinueButtonStyled>
      </ButtonContainer>
    </Container>
  );
};

export default IconList;

const Container = styled.div`
  padding: 2rem 1rem 6rem;
  max-width: 30.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
    padding: 1rem 1rem 6rem;
  }
`;

const Title = styled.p`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 1.5rem;
  span {
    color: #dc4b4b;
  }
  @media ${tablet} {
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    border-top: 1px solid #f5f5f5;
    background: #fff;
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;
const ListContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
`;

const ListItem = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
`;

const ListTitle = styled.h4`
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  padding-top: 3px;
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const ListText = styled.p`
  color: #777;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const TextContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.47rem;
`;

const SvgContainer = styled.section`
  width: 2rem;
  height: 2rem;
  svg {
    width: 2rem;
    height: 2rem;
  }
  @media ${tablet} {
    width: 1.5rem;
    height: 1.5rem;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;
